import artistsClarisaJonathan from "../../assets/Artista_ClarisaJonathan2025.svg";
import artistAnna from "../../assets/Artista_Anna2025.svg";
import artistAndre from "../../assets/Artista_Andre2025.svg";
import circulo from "../../assets/image.png";
import artistsYasminaMuller from "../../assets/Artista_YasminaMuller.svg";
import artistsCynthiaSebastian from "../../assets/Artista_CynthiaSebastian.svg";
// import artistMamana from "../../assets/Artista_Mamana.svg";
// import orquestra from "../../assets/Orquestra.svg";
import "./artists.css"

function Artists() {
  return (
    <section id="anchor_artists" className="Artists">
      <a className="button-ingress-top">
        FALE COM A ORGANIZAÇÃO
      </a>
      <a className="button-ingress-pink">
      </a>
      <h3 className="title-atists">Artistas</h3>

      <div className="block1">
        <div className="subBlock1">
          <img className="image-artist" src={artistsClarisaJonathan} />
        </div>
        <div className="artist-description-anna">
          <p className="subtitle">Clarisa Aragón & Jonathan Saavedra</p>
          <p className="subtitle-nac">ARGENTINA</p>
          <p className="letras">Ela, iniciou como bailarina clássica aos 9 anos de idade, e em 2007 se juntou ao Estúdio de jazz Eugenia Calamita. Desde 2009 foi introduzida ao tango, quando teve suas primeiras aulas com seus pais Hugo Aragón e Adriana Diani.</p>
          <p className="letras">Ele, iniciou seus estudos de folclore aos 5 anos de idade, participou de vários grupos de dança, chegando a dirigir apresentações e aberturas de festivais importantes dentro da província. No final de 2010 fez suas primeiras aulas de tango com os professores Valeria e Julio Saavedra.</p>
          <p className="letras">Em 2012, iniciaram seu trabalho profissional juntos com um estilo muito peculiar de dançar e ensinar. E em agosto de 2015 se consagraram Campeões Mundiais de Tango Salão. Hoje, viajam pelo mundo deixando contribuindo para a difusão do Tango nos mais famosos festivais e eventos do mundo.</p>

        </div>

      </div>

      <section className="container-video" id="video-ajust">
        <iframe src="https://www.youtube.com/embed/s-uQyfAJVyY?si=4OC6V9Wv3tAv5vjk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe src="https://www.youtube.com/embed/_B3I74r3dso?si=7rUK5m_IcFo8LNF5"></iframe>
      </section>

      <div className="block2">
        <div className="subBlock1">
          <img className="image-artist" src={artistAnna} />
        </div>
        <div className="artist-description-anna">
          <p className="subtitle">Anna Elisa</p>
          <p className="subtitle-nac">BRASIL</p>
          <p className="letras">Anna Elisa ganhou sua grande reputação a nível nacional sendo a pioneira no ensino do Tango em Brasília. Com mais de duas décadas de experiência com o Tango Argentino é amplamente reconhecida a nível nacional e internacionalmente por seus feitos dedicados ao ritmo portenho.</p>
          <p className="letras">Formou centenas de alunos e profissionalizou dançarinos com seu método próprio, Descomplitango, que consiste em facilitar o ensino-aprendizagem do Tango Argentino tornando-o acessível a todos.</p>
          <p className="letras">Anna Elisa participou de shows junto a grandes nomes do Tango reconhecidos internacionalmente como Claudio Villagra, Roberto Herrera, Junior Cervilla, Aurora Lubiz entre outros.</p>
          <p className="letras">Consagrou-se campeã nacional do I Campeonato de Tango do Brasil e dançou como principal representante do país na semifinal do Campeonato Mundial de Tango em Buenos Aires no ano de 2005.</p>
          <p className="letras">Devido ao seu excelente profissionalismo e destaque, Anna Elisa foi diversas vezes convocada pelas embaixadas da Argentina e do Brasil para representar o Tango na Feira Internacional do Turismo, em Buenos Aires, e da Arabian Travel Market, em Dubai.</p>
          <p className="letras">Por dois anos Anna fez parte dos grupo de maestros do Cruzeiro Internacional Tango & Milonga da empresa Costa Cruzeiros que percorreu diversos países como Argentina e Uruguai.</p>
          <p className="letras">Anna conta com uma forte formação em Ballet Clássico havendo dedicado 10 anos no Teatro Municipal do Rio de Janeiro e em renomadas escolas de Brasília como a Escola de Dança Norma Lilia e Lúcia Toller.</p>
          <p className="letras">Anna Elisa fez diversas viagens a Buenos Aires onde dedicou vários anos aprimorando sua técnica e conhecimento sobre o Tango Argentino com artistas e mestres altamente reconhecidos como Graciela Gonzalez, Osvaldo Zotto e Lorena Ermocida, Guillermina Quiroga, Yuyu Herrera, Claude Murga, Julio Balmaceda, Corina de La Rosa, Aurora Lubiz, Moira Castellano, Claudia Codega, Esteban Moreno, Facundo e Kelly, Claudio Villagra e Helena Fernandez, Sebastian Arce, Mariana Montes entre outros.</p>
          <p className="letras">Além das habilidades artísticas, Anna Elisa conta com a formação de Jornalismo sendo graduada pela instituição Universidade Católica de Brasília e pós Graduada pela Universidade Gama Filho, tal o que lhe confere uma excelente e única capacidade de comunicação com os seus pares e alunos.</p>
        </div>

      </div>

      <section className="container-video" id="video-ajust">
        <iframe height="315" src="https://www.youtube.com/embed/5LoH9YVNsno?si=0HyNyPABQtKhB9in" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe height="315" src="https://www.youtube.com/embed/QrkEP1QGC10?si=vTiAla7YB937j5sC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>

      <div className="block2">
        <div className="subBlock1">
          <img className="image-artist2" src={artistAndre} />
        </div>
        <div className="artist-description-anna">
          <p className="subtitle">André Carvalho</p>
          <p className="subtitle-nac">BRASIL</p>
          <p className="letras">Dedicado dançarino de Tango com 28 anos de experiência no campo do Tango Social. Diretor e professor da renomada tangueria do Rio de Janeiro, a "Casa do Tango", em parceria com Alice Vasques. Combinando sua paixão pela dança com uma formação acadêmica sólida, André também possui conhecimentos nas áreas de filosofia, psicologia e psicanálise.</p>
          <p className="letras">— Responsável por ministrar aulas de Tango em suas diversas vertentes, incluindo o Tango Milongueiro, Salão e Tango Nuevo.</p>
          <p className="letras">— Desenvolvimento de programas de ensino personalizados para estudantes de todos os níveis.</p>
          <p className="letras">— Organização e coordenação de eventos e milongas para promover a cultura do Tango na comunidade local.</p>
        </div>
      </div>

      <section className="container-video">
        <iframe src="https://www.youtube.com/embed/9hiPon7jBrg?si=Y6zvXfEIKVJhNuE3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe src="https://www.youtube.com/embed/2PhoYckR8eM?si=7B4boESsDFGc8HuP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>


      <div className="block2">
        <div className="subBlock1">
          <img className="image-artist" src={artistsYasminaMuller} />
        </div>
        <description className="artist-description-anna">
          <p className="subtitle">Yasmina Mamana & Müller Dantas</p>
          <p className="subtitle-nac">ARGENTINA E BRASIL</p>
          <p className="letras">Ela é professora, bailarina, dj e organizadora de eventos de Tango. Nascida em Formosa - Argentina, formou-se em Tango na cidade de Buenos Aires, onde estudou com grandes maestros e fez parceria com renomados dançarinos.</p>
          <p className="letras">Sua trajetória competitiva começou em 2012, desde então chegou na final do Mundial de Tango Pista três vezes (2012, 2016 e 2018) compartilhando o palco do tradicional Luna Park em Buenos Aires com os melhores bailarinos de Tango do Mundo.</p>
          <p className="letras">Atualmente continua desenvolvendo sua profissão em São Paulo, sua nova residência. Onde junto com seu parceiro, Müller Dantas, dão aulas regulares de Tango Pista na Escola da Nossa Casa Arte e Dança desde 2020.</p>
          <p className="letras">Ele descobriu seu lado dançarino ainda criança: começou a dançar forró aos quatorze anos e ingressou, em 2006, como bolsista na Escola de Dança Jaime Arôxa Liberdade e iniciou seus estudos em Dança de Salão.</p>
          <p className="letras">Em 2008 decidiu dedicar-se ao Tango, e desde então vem aprimorando sua técnica, frequentando aulas com grandes mestres do Tango argentino.</p>
          <p className="letras">O ano de 2014 foi de muito reconhecimento para o artista: foi premiado com o Tango de Oro, um prêmio conceituado no Brasil, e consagrou-se campeão do Prêmio Desterro do Festival de Dança de Florianópolis, na categoria Dança de Salão Tradicional - Duo Adulto, conquistando muitos prêmios nos anos seguintes.</p>

        </description>
      </div>

      <section className="container-video">
        <iframe src="https://www.youtube.com/embed/dxIGae6K53E?si=DNkzvsrdC9bjmHGK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe src="https://www.youtube.com/embed/d46PytOqj90?si=dDuT8234KsRhRd05" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>

      <div className="block2">
        <div className="subBlock1">
          <img className="image-artist" src={artistsCynthiaSebastian} />
        </div>
        <description className="artist-description-anna">
          <p className="subtitle">Cynthia Palácios e Sebastian Bolivar</p>
          <p className="subtitle-nac">ARGENTINA</p>
          <p className="letras">Ela teve seu início com a dança muito pequena, aos três anos de idade, e no Tango começou aos 11 anos.</p>
          <p className="letras">Formada em Dança Clássica e Danças Espanholas, além de ser técnica em psicologia social.</p>
          <p className="letras">Campeã patagônica de Tango Pista e Escenário nos anos de 2007, 2008, 2011, 2012, 2015 e 2017. Fundadora e professora da escola de danças “Arte y Estilo”.</p>
          <p className="letras">Ele iniciou na dança aos cinco anos de idade participando de uma grande companhia de dança de sua cidade natal e aos 13 iniciou sua paixão pelo Tango.</p>
          <p className="letras">Bailarino de folclore com ampla formação em bombo e boleadoras. Bailarino por 2 anos do renomado Café Tortoni em Buenos Aires. Atingiu o 4º posto no Mundial de Tango de Buenos Aires nos anos de 2017 e 2018.</p>
          <p className="letras">Juntos, Seba e Cynthia dançam desde 2019. Jovens milongueros e professores, coaches de casais em competições e jurados de certames de baile ao redor do mundo. Consagraram-se campeões mundiais de Tango Pista em 2022.</p>
        </description>
      </div>

      <section className="container-video">
        <iframe src="https://www.youtube.com/embed/Oa2-XRveMMA?si=CybU140OGE6EhJ7z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe src="https://www.youtube.com/embed/6MDGj0-py4o?si=hj5ITCUtbL6mhnxc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>

      <a className="button-ingress-top" style={{marginTop: 50}}>
        FALE COM A ORGANIZAÇÃO
      </a>
      <a className="button-ingress-pink" style={{marginTop: 10}}>
      </a>

    </section>


  );
}

export default Artists;
