import React, { useState } from "react";
import logoPranchetaHeader from "../../assets/BTF_logoPranchetaHeader.svg"
import "./Header.css";



function Header({setSelectPage}) {
  const [menuOpen, setMenuOpen] = useState(true)

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }


  function showMobile() {
    setSelectPage(0)
    return setMenuOpen(!menuOpen)
  }

return (
  <nav className="Header">
      <img style={{ height: 149, width: 200 }} className="imageLogo" src={logoPranchetaHeader} />
    <ul className={menuOpen ? "nav__menu" : "nav__menu nav__active"}>
      <li className="nav__item">
        <a onClick={() => {showMobile()}} href="https://wa.me/+5561984840055?text=Quero%20participar%20do%20Bras%C3%ADlia%20Tango%20Festival%20" target="_blank" className="buttonHeaderBorder">
        FALE COM A ORGANIZAÇÃO
        </a>
      </li>
      <li className="nav__item lineMenu">
        <a onClick={() => {showMobile()}} href="#anchor_artists" className="buttonHeader">
        Artistas
        </a>
      </li>
      <li className="nav__item lineMenu">
        <a onClick={() => {showMobile()}} href="#anchor_event" className="buttonHeader">
        O Festival
        </a>
      </li>
      <li className="nav__item lineMenu">
        <a onClick={() => setSelectPage(1)} href="#anchor_program" className="buttonHeader">
        Espetáculo
        </a>
      </li>
      {/* <li className="nav__item lineMenu">
        <a onClick={() => {showMobile()}} href="https://bit.ly/espetaculodetango" target="_blank" className="buttonHeaderBorder">
        Comprar Espetáculo
        </a>
      </li> */}
    </ul>
    <div onClick={toggleMenu} className={menuOpen ? "nav__toggler" : "nav__toggler toggle"}>
      <div className="line1"></div>
      <div className="line2"></div>
      <div className="line3"></div>
    </div>
  </nav>
);
}

export default Header;