import "./selling.css";
import vendaOne from "../../assets/venda-one.svg";
import vendaTwo from "../../assets/venda-two.svg";
import vendaThre from "../../assets/venda-thre.svg";
import vendaFour from "../../assets/venda-four.svg";


function Selling() {
  return (
    <>
      <section className="About">
        <a className="button-ingress-top">
          FALE COM A ORGANIZAÇÃO
        </a>
        <a className="button-ingress-pink">
        </a>
        <img className="image-artist" src={vendaOne} style={{marginTop: 50}} />
        <img className="image-artist" style={{ marginTop: 60, height: 400 }} src={vendaTwo} />
        <img className="image-artist" style={{ marginTop: 60 }} src={vendaThre} />
        <img className="image-artist" style={{ marginTop: 60 }} src={vendaFour} />
      </section>
    </>


  );
}

export default Selling;
