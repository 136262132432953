import { useState, useEffect } from "react";
import logoPranchetaMain from "../../assets/BTF_logoPranchetaMain.svg";
import backGround from "../../assets/backGround.svg";
import detalheSite from "../../assets/DetalheSite.svg";
import "./start.css";

function Start() {
  const [isBoxVisible, setIsBoxVisible] = useState(false);

  const handleButtonClick = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  useEffect(() => {
    handleButtonClick();
  }, []);

  return (
    <section style={{ marginBottom: 100 }} id="anchor_event" className="Start">
      <div className={`box ${isBoxVisible ? "slide-in" : "slide-out"}`} style={{ width: '100%' }}>
        <img
          className="logo-img"
          style={{ marginTop: 130, marginBottom: 10, zIndex: 2, position: "absolute", left: '35%'}}
          src={logoPranchetaMain}
          alt="Logo Prancheta Main"
        />
        <img
          className="background-img"
          style={{ width: '100%', zIndex: 1, position: "relative", height: '900px' }}
          src={backGround}
          alt="Logo Prancheta Main"
        >
        </img>
        <p className="date-rosa" style={{position: 'absolute', bottom: '15%', left: '35%'}}>13 a 16 de março de 2025</p>
      </div>
      <div className="names-artists">
        <div>
          <p className="contNames">CLARISA ARAGÓN &</p>
          <p>JONATHAN SAAVEDRA</p>
        </div>
        <div>
          <p className="contNames">ANNA ELISA &</p>
          <p>ANDRÉ CARVALHO</p>
        </div>
        <div>
          <p className="contNames">YASMINA MAMANA &</p>
          <p>MÜLLER DANTAS</p>
        </div>
        <div>
          <p className="contNames">CYNTHIA PALÁCIOS &</p>
          <p>SEBASTIAN BOLIVAR</p>
        </div>

      </div>
    </section>
  );
}

export default Start;
