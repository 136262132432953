import "./hotel.css";
import hotel from "../../assets/conforHotel.svg";



function Hotel() {
  return (
    <>
      <section className="About">
        <img className="image-artist" src={hotel} style={{ marginTop: 50 }} />
        <button
          onClick={() => window.open("https://atlantica.letsbook.com.br/reserva/link-publico?r=b2b17c2f56a044faaa415d1750da1710&p=tango", "_blank")}
          style={{ width: 500, height: 50, borderRadius: 20, border: 'none', backgroundColor: '#FF0093', fontSize: 20, marginBottom: 40, cursor: 'pointer' }}>
          Clique aqui para mais informações
        </button>
      </section>
    </>


  );
}

export default Hotel;
