import "./about.css";
import hotel from "../../assets/Hotel.svg";


function About() {
  return (
    <>
      <section className="About">
        <h3 style={{ marginBottom: 20 }} className="title-about">
          Sobre o Festival
        </h3>
        <p className="descrip-about">
          O Brasília Tango Festival é o primeiro evento internacional de Tango de
          Brasília que oferece ao público e participantes um mergulho nessa icônica
          dança Argentina. Com programação de oficinas (aulas) e Milongas (bailes),
          o evento promove o contato e a experiência com a cultura argentina por
          meio da dança.
        </p>
        <p className="descrip-about">
          A atmosfera contagiante das apresentações e atividades do Festival se firmam
          no intercâmbio cultural de profissionais brasileiros e argentinos que
          proporcionará aos amantes dessa arte, dias de muito conhecimento e
          entretenimento.
        </p>
        <p className="descrip-about">
          Com um elenco de artistas e professores de altíssimo gabarito, dentre eles
          campeões brasileiros e campeões mundiais, o BTF oferece à Brasília uma
          imersão no universo tangueiro em que iniciantes e profissionais compartilharão
          conhecimento e experiências. A primeira edição do Festival, realizada em 2020,
          contou com mais de 150 participantes, lançando Brasília no circuito de grandes
          eventos de Tango do país. Além de Brasília, Goiânia, Uberlândia, Cuiabá, Rio de
          Janeiro, Recife e Fortaleza marcaram presença e fortaleceram a capital brasileira
          como referência da comunidade tangueira do Brasil.
        </p>
        <p className="descrip-about">
          Em 2022, o Festival foi financiado com o Fundo de Apoio a Cultura (FAC) oferecendo
          a mais de 200 participantes uma imersão cultural no Tango.
        </p>
      </section>
      {/* <section className="About">
        <h3 style={{ marginBottom: 20 }} className="title-about">
          Hotel parceiro
        </h3>
        <img className="hotel" src={hotel} />
        <h3 style={{ marginBottom: 20 }} className="title-about">
          Tarifas especiais
        </h3>

        <div class="table">
          <div class="row">
            <div class="header">Tipo de apartamento</div>
            <div class="header">Diária especial</div>

          </div>
          <div class="row">
            <div class="cell">Single</div>
            <div class="cell">R$ 273/diária</div>


          </div>
          <div class="row">
            <div class="cell">Duplo Twin ou Casal</div>
            <div class="cell">R$ 303/diária</div>

          </div>
          <div class="row">
            <div class="cell">Triplo - 3 camas de solteiro</div>
            <div class="cell">R$ 333/diária</div>

          </div>
        </div>


        <p className="descrip-hotel">
          *Incluso café da manhã no restaurante do hotel
        </p>
      </section> */}


      {/* <a
        href="https://wa.me/+5561984840055?text=Quero%20participar%20do%20Bras%C3%ADlia%20Tango%20Festival%20"
        target="_blank"
        className="button-ingress-value"
      >
        FALE COM A ORGANIZAÇÃO
      </a> */}


    </>


  );
}

export default About;
