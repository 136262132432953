import './App.css';
import About from './components/about/about';
import Artists from './components/artists/artists';
import Dates from './components/dates/dates';
import Footer from './components/footer/footer';
import Header from './components/header/Header';
import Start from './components/start/start';
import Loc from './components/loc/loc';
import { useState } from 'react';
import ShowTango from './components/showtango/showTango';
import Selling from './components/selling/selling';
import Hotel from './components/hotel/hotel';

function App() {
  const [selectPage, setSelectPage] = useState(0)
  const ShowPage = () => {
    if(selectPage === 0) {
      return (
        <>
      <Start/>
      <Selling/>
      <Dates/>
      <Loc/>
      <Hotel/>
      <Artists/>
      <About/>
        </>
      )
    } else{
      return(
      <>
      <ShowTango/>
      </>
      )
    }
  } 
  return (
    <div className="App">
      <Header setSelectPage = {setSelectPage} />
      {ShowPage()}
    </div>
  );
}

export default App;
