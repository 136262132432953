import "./loc.css";

function Loc() {
  return (

    <section id="anchor_localization" style={{marginBottom: 50}}>
      <p className="title-map">
        Localização do Festival
      </p>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.2954617178552!2d-47.8440421!3d-15.7883672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3bc513132a29%3A0x3d61690a6140de87!2sClube%20da%20Aeron%C3%A1utica%20de%20Brasilia!5e0!3m2!1spt-BR!2sbr!4v1736739161207!5m2!1spt-BR!2sbr" className="map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>


  );
}

export default Loc;



